<!-- © 2021 Tfarraj -->

<template>
  <v-card class="content-bg card mx-auto" :max-width="card.maxWidth" flat tile>
    <router-link :to="`/watch/${video._id}`">
      <img-with-auth :id="video._id" :type="'video'">
        <span v-if="this.video.stage === 'live'" class="status-left-span">
          <v-badge
            color="deep-purple accent-4"
            icon="mdi-account-switch-outline"
            overlap
          >
            <v-btn x-small class="white--text" color="red" depressed>
              {{ $t('videoCard.live') }}
            </v-btn>
          </v-badge>
        </span>
        <span
          v-if="hourDataNew(video.createdAt) <= 24"
          class="status-right-span"
        >
          <v-badge color="deep-purple accent-4" icon="mdi-movie-open" overlap>
            <v-btn x-small class="white--text" color="green" depressed>
              {{ $t('videoCard.new') }}
            </v-btn>
          </v-badge>
        </span>
      </img-with-auth>
    </router-link>

    <v-row no-gutters>
      <v-col cols="2" v-if="card.type != 'noAvatar'">
        <v-list-item
          class="pl-0 pt-3"
          router
          :to="`/channels/${video.channel._id}`"
        >
          <v-list-item-avatar>
            <img-with-auth
              v-if="video.channel.photo"
              class="elevation-6"
              :id="video.channel.id"
              :type="'channel'"
            />
            <v-avatar v-else color="indigo darken-4">
              <span class="white--text headline">
                {{ video.channel.title.split('')[0].toUpperCase() }}</span
              >
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>
      </v-col>

      <v-col>
        <v-card-title
          class="pl-2 pt-3 subtitle-1 font-weight-bold"
          style="line-height: 1.2rem"
        >
          <v-layout>
            <span v-snip="2">
              {{ video.title }}
            </span>
            <v-badge
              v-if="this.video.views >= 10"
              color="deep-purple accent-4"
              icon="mdi-airplane-takeoff"
              offset-x="-5"
              offset-y="20"
            ></v-badge>
          </v-layout>
        </v-card-title>

        <v-card-subtitle class="pl-2 pb-0">
          {{ video.channel.title }}
        </v-card-subtitle>
        <v-card-subtitle class="pl-2 pt-0">
          {{ video.views }} {{ $t('videoCard.views')
          }}<v-icon>mdi-circle-small</v-icon> {{ formatDate(video.createdAt)
          }}<v-icon>mdi-circle-small</v-icon>
          {{ secondsToFormattedDuration(video.durationSeconds) }}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <div class="pl-0 pt-0">
      <!-- <v-btn x-small class="white--text" color="indigo darken-4" depressed>
        #{{ video.chanConcat }}</v-btn
      > -->

      <v-btn
        v-if="video.contentLanguage != ''"
        router
        :to="`/search?search-query=${video.contentLanguage}`"
        x-small
        class="white--text"
        color="teal lighten-1"
        depressed
      >
        {{ video.contentLanguage }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import ImgWithAuth from '@/components/ImgWithAuth';
import DateMixin from '@/mixins/DateMixin.ts';

export default {
  name: 'VideoCard',
  mixins: [DateMixin],
  props: {
    video: {
      type: Object,
      required: true,
    },
    card: Object,
  },
  data() {
    return {
      url: Config.apiUrl,
      hours: 0,
    };
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      }
      return text;
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  components: {
    ImgWithAuth,
  },
};
</script>

<style>
.status-right-span {
  padding-top: 10px;
  padding-right: 10px;
  float: right;
}
.status-left-span {
  padding-top: 10px;
  padding-left: 10px;
  float: left;
}
.subtitle-1 {
  word-break: normal;
}
</style>
