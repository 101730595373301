<!-- © 2021 Tfarraj -->
<template>
  <div id="mhome" class="pa-4">
    <v-container fluid>
      <v-alert prominent type="error" v-if="errored">
        <v-row align="center">
          <v-col class="grow">
            <div class="title">Error!</div>
            <div>
              Something went wrong, but don’t fret — let’s give it another shot.
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="getVideos">Take action</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <main v-else>
        <!-- <h3 class="headline font-weight-medium">Recommended</h3> -->

        <v-row>
          <!-- <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="(video, i) in loading ? 12 : videos"
            :key="i"
            class="mx-xs-auto"
          >
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card
                :card="{ maxWidth: 350 }"
                :video="video"
              ></video-card>
            </v-skeleton-loader>

            <div class="swiper-container">
              <div class="swiper-wrapper">

                <div class="swiper-slide">
                  <img data-src="path/to/picture-1.jpg" class="swiper-lazy" />
                  <div class="swiper-lazy-preloader"></div>
                </div>
          </v-col> -->

          <div v-swiper:mySwiper="swiperOption" :key="componentKey">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(video, i) in loading ? 3 : videos"
                :key="i"
              >
                <div class="shaka">
                  <video-player
                    :options="playerOptions"
                    ref="videoPlayer"
                    :license-server="`https://widevine-proxy.appspot.com/proxy`"
                    :manifest-url="`${apiUrl}/uploads/videos/${video.url}`"
                    :poster-url="`${apiUrl}/api/v1/videos/${video.id}/thumbnail`"
                    :content-title="`${video.title}`"
                    :page-url="`${currentUrl}`"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="swiper-pagination"></div> -->
          </div>
          <v-col class="text-center" v-if="videos.length === 0 && !loading">
            <p>No videos yet</p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <infinite-loading @infinite="getVideos">
              <div slot="spinner">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </div>
              <div slot="no-results"></div>
              <span slot="no-more"></span>
              <div slot="error" slot-scope="{ trigger }">
                <v-alert prominent type="error">
                  <v-row align="center">
                    <v-col class="grow">
                      <div class="title">Error!</div>
                      <div>
                        Something went wrong, but don’t fret — let’s give it
                        another shot.
                      </div>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="trigger">Take action</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>
            </infinite-loading>
          </v-col>
        </v-row>
      </main>
    </v-container>
  </div>
</template>

<script>
import Config from '@/config';
import InfiniteLoading from 'vue-infinite-loading';
import VideoService from '@/services/VideoService';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { mapGetters } from 'vuex';

export default {
  name: 'Mhome',
  data: () => ({
    loading: false,
    loaded: false,
    errored: false,
    currentUrl: '',
    videos: [],

    componentKey: 0,
    apiUrl: Config.apiUrl,
    page: 1,
    swiperOption: {
      loop: false,
      preloadImages: false,
      lazy: {
        loadPrevNextAmount: 1,
        checkInView: true,
      },
    },
    playerOptions: {
      autoplay: false,
      controls: false,
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    directives: {
      swiper: directive,
    },
    // mounted() {
    //   console.log("Current Swiper instance object", this.mySwiper);
    //   // this.mySwiper.slideTo(0, 0, false);
    // },
  }),

  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    async getVideos($state) {
      if (!this.loaded) {
        this.loading = true;
      }

      this.currentUrl = window.location.pathname;
      const videos = await VideoService.getAll('public', { page: this.page })
        .catch((err) => {
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });

      if (typeof videos === 'undefined') return;

      if (videos.data.data.length) {
        this.page += 1;
        this.videos.push(...videos.data.data);
        $state.loaded();
        this.loaded = true;
      } else {
        $state.complete();
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  components: {
    InfiniteLoading,
    VideoPlayer,
  },
};
</script>

<style lang="scss">
video {
  max-width: 100%;
}
.shaka {
  height: 800;
  margin: 0 auto;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: top;
  text-align: center;
  background-color: #000000;
}
</style>
