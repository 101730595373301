// © 2021 Tfarraj
import Api from '@/services/Api';

export default {
  getOwnFeelingsForVideos(page, feeling) {
    return Api().get('feelings/videos', {
      params: {
        page,
        limit: 12,
        feeling,
      },
    });
  },
};
